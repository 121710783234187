@font-face{
    font-family: 'Bahnschrift';
    src: local('Bahnschrift'), url('../fonts/bahnschrift.ttf') format('truetype');
}

/* Retromark */
@font-face{
    font-family: 'Retromark';
    src: local('Retromark'), url('../fonts/Retromark-Script-UNICODE-Dafont.otf') format('opentype');
}

/* Shorelines */
@font-face{
    font-family: 'Shorelines';
    src: local('Shorelines'), url('../fonts/Shorelines-Script-Bold.otf') format('opentype');
}

/* Kaufmann */
@font-face{
    font-family: 'Kaufmann';
    src: local('Kaufmann'), url('../fonts/Kaufmann.ttf') format('truetype');
}

/* PerpetuaRegular */
@font-face{
    font-family: 'PerpetuaRegular';
    src: local('PerpetuaRegular'), url('../fonts/PER_____.TTF') format('truetype');
}

/* PerpetuaBold */
@font-face{
    font-family: 'PerpetuaBold';
    src: local('PerpetuaBold'), url('../fonts/PERB____.TTF') format('truetype');
}

/* PerpetuaItalic */
@font-face{
    font-family: 'PerpetuaItalic';
    src: local('PerpetuaItalic'), url('../fonts/PERI____.TTF') format('truetype');
}

/* PerpetuaBoldItalic */
@font-face{
    font-family: 'PerpetuaBoldItalic';
    src: local('PerpetuaBoldItalic'), url('../fonts/PERBI___.TTF') format('truetype');
}

/* Tahoma */
@font-face{
    font-family: 'Tahoma';
    src: local('Tahoma'), url('../fonts/tahoma.ttf') format('truetype');
}

/* TahomaBold */
@font-face{
    font-family: 'TahomaBold';
    src: local('TahomaBold'), url('../fonts/tahomabd.ttf') format('truetype');
}

/* YAnoneBold */
@font-face{
    font-family: 'YanoneBold';
    src: local('YanoneBold'), url('../fonts/YanoneKaffeesatzTTF/YanoneKaffeesatz-Bold.ttf') format('truetype');
}
@font-face{
    font-family: 'YanoneLight';
    src: local('YanoneLight'), url('../fonts/YanoneKaffeesatzTTF/YanoneKaffeesatz-Light.ttf') format('truetype');
}
@font-face{
    font-family: 'YanoneRegular';
    src: local('YanoneRegular'), url('../fonts/YanoneKaffeesatzTTF/YanoneKaffeesatz-Regular.ttf') format('truetype');
}